/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useState } from 'react'
import { Typography, Container, Button, Box, FormControlLabel, Switch, Tabs, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { Grid, LicensePlate, Text, Wrapper, Tag } from '../../components/layout'
import DetailsActionButton from '../../components/layout/detailsActionButton'
import { ImageContainer, Tab } from '../../components/layout/commonUiElements'
import { getReservationById } from '../../actions/getReservationsActions'
import { getWasherHistory } from '../../actions/getWasherHistoryActions'
import { togglePromoChangeModal } from '../../actions/uiStatesAction'
import { IPromotion } from '../../interfaces/promotion.interfaces'
import { API_URI, WASHERID_COOKIE_KEY } from '../../config'
import { IStore } from '../../interfaces/store.interfaces'
import WashImage from '../../components/layout/washImage'
import { langMap } from '../../helpers/language.helper'
import { headers } from '../../helpers/headers.helper'
import ImageSlider from '../../modules/imageSlider'
import Promotions from '../../modules/promotions'
import { colors } from '../../theme.styles'
import {
  NavigationWrapper,
  HeaderSection,
  PaperWrapper,
  AuthorAvatar,
  CommentField,
  TitleSection,
  HistoryItem,
  MainSection,
  NavButton,
  Line,
  Tags,
} from './reservationdetails.styles'

const ReservationDetails = (): ReactElement => {
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()

  const washerHistory = useSelector((state: IStore) => state.washerHistory.reservationlist)
  const reservations = useSelector((state: IStore) => state.reservations.reservationlist)
  const slots = useSelector((state: IStore) => state.slots)
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  const reservation = reservations?.find((res) => res.id === Number(id))

  const slotTitle = slots.find(({ slot }) => parseFloat(slot) === reservation?.slot)?.resourceTitle
  const startingTime = moment(reservation?.startTime).format('HH:mm')
  const endingTime = moment(reservation?.startTime)
    .add(+(reservation?.duration || 0), 'minutes')
    .format('HH:mm')

  const [commentType, setCommentType] = useState('WASH_COMMENT')
  const [statusMessage, setStatusMessage] = useState('')
  const [imageOpen, setImageOpen] = useState(false)
  const [activeImage, setActiveImage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [comment, setComment] = useState('')
  const [local, setLocal] = useState(true)

  useEffect(() => {
    if (washerId) {
      dispatch(getReservationById(washerId, id))
    }
  }, [washerId])

  useEffect(() => {
    if (washerId && reservation && reservation.vehicle) {
      dispatch(getWasherHistory(washerId, reservation.vehicle.licensePlate, 1, 20))
    }
  }, [washerId, reservation])

  const commentOnVehicleData = {
    message: comment,
    local,
  }

  const commentOnReservationData = {
    message: comment,
  }

  const onCommentOnVehicle = async () => {
    setLoading(true)
    const postUrl = `${API_URI}/vehicles/${reservation?.vehicle?.id}/comments`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(commentOnVehicleData),
    })
    const result = await response.json()

    if (!!result && response.status !== 200) {
      setStatusMessage(t('COMMENT_SEND_FAIL'))
      setLoading(false)
      setTimeout(() => {
        setStatusMessage(t(''))
      }, 3000)
    }
    if (!!result && response.status === 200 && !!washerId) {
      dispatch(getReservationById(washerId, id))
      setStatusMessage(t('COMMENT_SEND_SUCCESS'))
      setLoading(false)
      setComment('')
      setTimeout(() => {
        setStatusMessage(t(''))
      }, 3000)
    }
  }

  const onCommentOnReservation = async () => {
    setLoading(true)
    const postUrl = `${API_URI}/washers/${washerId}/reservations/${reservation?.id}/comments`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(commentOnReservationData),
    })
    const result = await response.json()

    if (!!result && response.status !== 200) {
      setStatusMessage(t('COMMENT_SEND_FAIL'))
      setLoading(false)
      setTimeout(() => {
        setStatusMessage(t(''))
      }, 3000)
    }
    if (!!result && response.status === 200 && !!washerId) {
      dispatch(getReservationById(washerId, id))
      setStatusMessage(t('COMMENT_SEND_SUCCESS'))
      setLoading(false)
      setComment('')
      setTimeout(() => {
        setStatusMessage(t(''))
      }, 3000)
    }
  }

  const openImage = (images: string[], imageKey: string) => {
    setImageOpen(true)
    setActiveImage(images.indexOf(imageKey))
  }

  const shortWasherHistory = washerHistory.slice(0, 3)

  const buttonDetails = {
    licenseNumber: reservation?.vehicle?.licensePlate,
    workingTime: reservation?.workingTime,
    lastStatus: reservation?.lastStatus,
    startTime: reservation?.startTime,
    duration: reservation?.duration,
    reservationId: parseFloat(id),
    onDetailsPage: true,
  }

  return (
    <Container>
      <NavigationWrapper>
        <NavButton $fontSize="30px" disableElevation component={Link} to="/reservations" variant="contained">
          ‹
        </NavButton>
        <Text $margin="0 0 0 20px" $lettercase="capitalize" $size="30px" $weight={500}>
          {t('DETAILS')}
        </Text>
      </NavigationWrapper>
      {reservation && reservation.source ? (
        <PaperWrapper elevation={0}>
          <HeaderSection>
            <Text $weight={400} $xxsSize="14px" $lgSize="20px">
              {reservation?.washerName}
            </Text>
            <Grid $xxsCol="1fr auto auto" $alignItems="center" justifyItems="start">
              {reservation && (
                <Text $weight={600} $xxsSize="20px" $lgSize="30px">
                  {`${t(reservation?.surface)} ${t(reservation.category?.title)}`}
                </Text>
              )}
              {!!reservation?.vehicle?.licensePlate && (
                <LicensePlate $large>{reservation.vehicle.licensePlate}</LicensePlate>
              )}
              <Text $weight={600} $xxsSize="30px" $lgSize="50px" $margin="0 0 0 30px" $textColor={colors.lime}>
                {slotTitle}
              </Text>
            </Grid>
            <Tags>
              {!!reservation?.category?.tasks?.length &&
                reservation.category?.tasks.map(({ title, id: taskId }) => (
                  <Tag key={taskId}>
                    <Text $xxsSize="11px" $lgSize="16px" $weight={500}>
                      {t(title)}
                    </Text>
                  </Tag>
                ))}
            </Tags>
          </HeaderSection>
          <MainSection $xsCol={2} $col={4}>
            <Box>
              <Typography variant="h5">{t('DATE')}</Typography>
              <Text $lettercase="capitalize" $size="20px" $weight={500}>
                {moment(reservation?.startTime)
                  .locale(langMap[userLang] || langMap.en)
                  .format('MMMM D.')}
              </Text>
              <Text $weight={500} $size="14px" $margin="-5px 0 0 0">
                {moment(reservation?.startTime).format('YYYY.')}
              </Text>
            </Box>
            <Box>
              <Typography variant="h5">{t('TIME')}</Typography>
              <Text $size="30px" $weight={500}>
                {reservation.lastStatus === 'COMPLETED' || reservation.lastStatus === 'FINISHED'
                  ? `${moment(reservation.firstStartTime).format('HH:mm')} - ${moment(reservation.finishedTime).format(
                      'HH:mm',
                    )}`
                  : `${startingTime} - ${endingTime}`}
              </Text>
            </Box>
            <Box>
              <Typography variant="h5">{t('WORK_DURATION')}</Typography>
              <Text $size="30px" $weight={500}>{`${
                reservation.lastStatus === 'FINISHED' || reservation.lastStatus === 'COMPLETED'
                  ? Math.ceil((reservation?.workingTime || 0) / 60)
                  : reservation?.duration
              } ${t('MINUTE')}`}</Text>
            </Box>
            <Box>
              <Typography variant="h5">{t('PRICE')}</Typography>
              <Text $size="20px" $weight={500}>
                {`${
                  typeof reservation.discountedPrice === 'number' ? reservation.discountedPrice || 0 : reservation.price
                } ${t(reservation.currency || 'HUF')} ${
                  (reservation.priceAdjustment &&
                    ` ${reservation.priceAdjustment > 0 ? '+' : ''} ${reservation.priceAdjustment} ${t(
                      reservation.currency || 'HUF',
                    )}`) ||
                  ''
                }`}
              </Text>
              <Grid $mdCol={2}>
                <Text $size="14px" $weight={500} $strikeThrough={!!reservation?.promotionalCode}>
                  {reservation?.promotionalCode ? `${reservation?.price} ${t(reservation.currency || 'HUF')}` : ''}
                </Text>
                <Text $size="14px" $weight={600} $textColor={colors.lime}>
                  {reservation.priceAdjustment &&
                    `= ${
                      (typeof reservation.discountedPrice === 'number'
                        ? reservation.discountedPrice || 0
                        : reservation.price || 0) + (reservation.priceAdjustment || 0)
                    } ${t(reservation.currency || 'HUF')}`}
                </Text>
              </Grid>
            </Box>
            <Box>
              <Typography variant="h5">{t('PRICE_ON_SITE')}</Typography>
              <Text $size="20px" $weight={500}>
                {`${typeof reservation.payableOnSite === 'number' ? reservation.payableOnSite : reservation.price} ${t(
                  reservation.currency || 'HUF',
                )} ${
                  (reservation.priceAdjustment &&
                    ` ${reservation.priceAdjustment > 0 ? '+' : ''} ${reservation.priceAdjustment} ${t(
                      reservation.currency || 'HUF',
                    )}`) ||
                  ''
                }`}
              </Text>
              <Grid $mdCol={2}>
                <Text $size="14px" $weight={500} $strikeThrough={!!reservation?.promotionalCode}>
                  {reservation?.promotionalCode ? `${reservation?.price} ${t(reservation.currency || 'HUF')}` : ''}
                </Text>
                <Text $size="14px" $weight={600} $textColor={colors.lime}>
                  {reservation.priceAdjustment &&
                    `= ${
                      (typeof reservation.discountedPrice === 'number'
                        ? reservation.discountedPrice || 0
                        : reservation.price || 0) + (reservation.priceAdjustment || 0)
                    } ${t(reservation.currency || 'HUF')}`}
                </Text>
              </Grid>
            </Box>
          </MainSection>
          {reservation?.lastStatus !== 'FINISHED' && (
            <MainSection>
              <DetailsActionButton {...buttonDetails} />
            </MainSection>
          )}
          <MainSection>
            <Typography variant="h2">{t('CUSTOMER_DETAILS')}</Typography>
          </MainSection>
          <MainSection $xsCol={3}>
            <Box>
              <Typography variant="h5">{t('FULL_NAME')}</Typography>
              <Typography>{`${reservation?.customerProfile?.firstName} ${reservation?.customerProfile?.lastName}`}</Typography>
            </Box>
            <Box>
              <Typography variant="h5">{t('EMAIL_ADDRESS')}</Typography>
              <Typography>
                {reservation?.customerProfile?.contacts?.length &&
                  reservation.customerProfile.contacts.find(({ channel }) => channel === 'EMAIL')?.value}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5">{t('PHONE_NUMBER')}</Typography>
              <Typography>
                {reservation?.customerProfile?.contacts?.length &&
                  reservation.customerProfile.contacts.find(({ channel }) => channel === 'PHONE')?.value}
              </Typography>
            </Box>
            {!!reservation?.vehicle && (
              <Box>
                <Typography variant="h5">{t('TYPE')}</Typography>
                <Typography>{`${reservation?.vehicle?.make || ''} ${reservation?.vehicle?.model || ''}`}</Typography>
              </Box>
            )}
            {!!reservation?.vehicle?.size && (
              <Box>
                <Typography variant="h5">{t('SIZE')}</Typography>
                <Typography>{reservation?.vehicle?.size}</Typography>
              </Box>
            )}
          </MainSection>
          <Line />
          {['CUSTOMER', 'MARKETING_CAMPAIGN'].includes(reservation.promotionalCodeSource ?? '') ? (
            <MainSection>
              <Text $size="16px" $weight={500}>
                {t('CUSTOMER_PROMOTION_INFO')}
              </Text>
              <Text $size="16px" $weight={500}>
                {`${t('SOURCE_OF_DISCOUNT')} ${reservation.promotionalCodeSourceName}`}
              </Text>
              <Text $size="16px" $weight={500}>
                {`${t('PROMOTION_CODE')}: ${reservation.promotionalCode?.amount}
                  ${reservation.promotionalCode?.unit === 'PERCENT' ? '%' : reservation.promotionalCode?.currency}`}
              </Text>
            </MainSection>
          ) : (
            <>
              {reservation.lastStatus !== 'FINISHED' && (
                <Promotions
                  selectedValue={reservation?.promotionalCode?.id.toString()}
                  onHandlePromotion={(promo?: IPromotion) => dispatch(togglePromoChangeModal('CHANGE', promo?.id))}
                  onRemovePromotion={(promo?: IPromotion) => dispatch(togglePromoChangeModal('REMOVE', promo?.id))}
                />
              )}
            </>
          )}
          {!!reservation.images && !!reservation.images.length && (
            <Grid>
              <Text $margin="10px 40px" $size="20px" $weight={600}>
                {t('WASH_IMAGES')}
              </Text>
              <Grid $padding="0px 20px" $mdCol={2} $col={4}>
                {reservation.images.map((image) => (
                  <ImageContainer key={image} onClick={() => openImage(reservation.images || [], image)}>
                    <WashImage image={image} thumbnail />
                  </ImageContainer>
                ))}
              </Grid>
            </Grid>
          )}
          {!!reservation.images?.length && (
            <ImageSlider
              setActiveImage={setActiveImage}
              setImageOpen={setImageOpen}
              images={reservation.images}
              activeImage={activeImage}
              imageOpen={imageOpen}
            />
          )}
          <Wrapper $margin="30px 15px">
            <TitleSection $xsCol="1fr auto">
              <Typography variant="h2">{t('LATEST_ORDERS')}</Typography>
              <Text $textColor={colors.lime} $weight={600} $size="14px">
                {`${washerHistory.length === 20 ? t('MORE_THAN') : ''} ${washerHistory.length} ${t('ORDER')}`}
              </Text>
            </TitleSection>
            <Grid $padding="0px 5px">
              {!!shortWasherHistory.length &&
                shortWasherHistory.map(({ category, startTime, washerName, surface }) => (
                  <HistoryItem
                    $xsCol="1fr auto auto"
                    $justifyItems="start"
                    $alignItems="center"
                    $xxsCol="1fr auto"
                    key={startTime}
                    $gap="20px"
                  >
                    <Box>
                      <Text $weight={600} $size="16px">
                        {`${t(surface)} ${t(category?.title)}`}
                      </Text>
                      <Text $weight={600} $size="12px" $textColor={colors.cyan}>
                        {washerName}
                      </Text>
                    </Box>
                    <Text $textColor={colors.grey} $size="12px" $weight={500} $lettercase="capitalize">
                      {moment(startTime)
                        .locale(langMap[userLang] || langMap.en)
                        .format('hh:mm - MMMM D. YYYY')}
                    </Text>
                    {reservation?.vehicle?.licensePlate && (
                      <LicensePlate $dark>{reservation.vehicle.licensePlate}</LicensePlate>
                    )}
                  </HistoryItem>
                ))}
            </Grid>
            <TitleSection $xsCol="1fr auto">
              <Typography variant="h2">{t('COMMENTS')}</Typography>
              <Text $textColor={colors.lime} $weight={600} $size="14px">
                {`${reservation?.comments?.length + (reservation?.vehicle?.comments?.length || 0) || 0} ${t(
                  'COMMENT',
                )}`}
              </Text>
            </TitleSection>
            <Grid $paddingLeft="10px" $paddingBottom="10px">
              <Tabs
                value={commentType}
                indicatorColor="primary"
                textColor="primary"
                onChange={(event: React.ChangeEvent<unknown>, newValue: string) => setCommentType(newValue)}
                aria-label="disabled tabs example"
              >
                <Tab label={t('WASH_COMMENT')} value="WASH_COMMENT" />
                <Tab disabled={!reservation.vehicle} label={t('VEHICLE_COMMENT')} value="VEHICLE_COMMENT" />
              </Tabs>
            </Grid>
            <Grid $padding="0px 5px 30px 5px" $justifyItems="end">
              <CommentField
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
                color="primary"
                placeholder={commentType === 'WASH_COMMENT' ? t('WASH_COMMENT') : t('VEHICLE_COMMENT')}
                value={comment}
                multiline
                rows={4}
              />
              <Grid
                $xsCol={commentType === 'WASH_COMMENT' ? 'auto auto' : 'auto auto auto'}
                $padding="0px 5px"
                justifyContent="end"
                $justifyItems="end"
                alignItems="center"
              >
                <Text $weight={300} $textColor={colors.grey} $size="15px">
                  {statusMessage}
                </Text>
                {commentType === 'WASH_COMMENT' ? (
                  <Button
                    onClick={() => onCommentOnReservation()}
                    disabled={!!loading}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {loading ? t('WAITING_FOR_COMMENT_POSTING') : t('SEND_MESSAGE')}
                  </Button>
                ) : (
                  <>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={local}
                          color="primary"
                          onChange={(e) => setLocal(e.target.checked)}
                          name="checkedA"
                        />
                      }
                      label={t('LOCAL_COMMENT')}
                    />
                    <Button
                      onClick={() => onCommentOnVehicle()}
                      disabled={!!loading}
                      variant="contained"
                      disableElevation
                      color="primary"
                    >
                      {loading ? t('WAITING_FOR_COMMENT_POSTING') : t('SEND_MESSAGE')}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid $padding="0px 5px">
              {!!reservation.comments?.length &&
                reservation.comments.map(({ author, createdAt, washerName, message }) => (
                  <HistoryItem $xsCol="auto 1fr auto" key={createdAt} $gap="20px">
                    <AuthorAvatar $colorSelector={user?.user?.id && user.user.id % 10}>{author[0]}</AuthorAvatar>
                    <Grid $gap="0px">
                      <Grid $xsCol="auto 1fr">
                        <Text $weight={600} $textColor={colors.lime} $size="12px">
                          {author}
                        </Text>
                        <Text $weight={600} $textColor={colors.cyan} $size="12px">
                          {washerName}
                        </Text>
                      </Grid>
                      <Text $size="14px">{message}</Text>
                    </Grid>
                    <Grid>
                      <Text $textColor={colors.grey} $size="12px" $weight={500} $lettercase="capitalize">
                        {moment(createdAt)
                          .locale(langMap[userLang] || langMap.en)
                          .format('HH:mm - MMMM D. YYYY')}
                      </Text>
                      <Text $textColor={colors.grey} $size="12px" $weight={500} $lettercase="capitalize">
                        {t('WASH_COMMENT')}
                      </Text>
                    </Grid>
                  </HistoryItem>
                ))}
              {!!reservation.vehicle?.comments?.length &&
                reservation.vehicle?.comments.map(({ author, createdAt, washerName, message, local: cLocal }) => (
                  <HistoryItem $xsCol="auto 1fr auto" key={createdAt} $gap="20px">
                    <AuthorAvatar $colorSelector={user?.user?.id && user.user.id % 10}>{author[0]}</AuthorAvatar>
                    <Grid $gap="0px">
                      <Grid $xsCol="auto 1fr">
                        <Text $weight={600} $textColor={colors.lime} $size="12px">
                          {author}
                        </Text>
                        <Text $weight={600} $textColor={colors.cyan} $size="12px">
                          {washerName}
                        </Text>
                      </Grid>
                      <Text $size="14px">{message}</Text>
                    </Grid>
                    <Grid>
                      <Text $textColor={colors.grey} $size="12px" $weight={500} $lettercase="capitalize">
                        {moment(createdAt)
                          .locale(langMap[userLang] || langMap.en)
                          .format('HH:mm - MMMM D. YYYY')}
                      </Text>
                      {!!cLocal && (
                        <Text $textColor={colors.grey} $size="12px" $weight={500} $lettercase="capitalize">
                          {t('LOCAL_COMMENT')}
                        </Text>
                      )}
                    </Grid>
                  </HistoryItem>
                ))}
            </Grid>
          </Wrapper>
        </PaperWrapper>
      ) : (
        <Grid $padding="40px" $alignItems="center" $justifyItems="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
    </Container>
  )
}

export default ReservationDetails
