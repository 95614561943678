/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useRef, useState } from 'react'
import { Box, Popover, IconButton } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { toggleEventCalEditing, toggleEventMoveModal } from '../../actions/uiStatesAction'
import DetailsActionButton from '../../components/layout/detailsActionButton'
import { Grid, LicensePlate, Text } from '../../components/layout'
import { PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../config'
import { IEvent } from '../../interfaces/event.interfaces'
import { IStore } from '../../interfaces/store.interfaces'
import { langMap } from '../../helpers/language.helper'
import { colors } from '../../theme.styles'
import {
  PopoverContent,
  ControlSection,
  HeaderSection,
  DetailsBtn,
  EventItem,
  CloseBtn,
  WashSize,
  Section,
} from './event.styles'

const Event = ({ event }: { event: IEvent }): ReactElement => {
  const {
    customerProfile,
    firstStartTime,
    finishedTime,
    workingTime,
    lastStatus,
    duration,
    category,
    vehicle,
    surface,
    start,
    id,
  } = event

  const [cookies] = useCookies([PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector((state: IStore) => state.user)
  const userLang = user.user?.language || 'en'

  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setAnchorEl(buttonRef.current)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const buttonDetails = {
    licenseNumber: vehicle?.licensePlate,
    reservationId: id,
    onDetailsPage: false,
    startTime: start,
    workingTime,
    lastStatus,
    duration,
  }

  const bookedStartingTime = moment(start).format('HH:mm')
  const bookedFinishingTime = moment(start)
    .add(duration || 0, 'minutes')
    .format('HH:mm')

  const realStartingTime = moment(firstStartTime).format('HH:mm')
  const realFinishingTime = moment(finishedTime).format('HH:mm')

  return (
    <div style={{ height: '100%' }}>
      <EventItem
        onDragStart={() => dispatch(toggleEventCalEditing('DRAG', id, duration))}
        draggable={calView === 'week' && lastStatus !== 'FINISHED'}
        $finished={lastStatus === 'FINISHED'}
        $active={lastStatus === 'ON_GOING'}
        $completed={lastStatus === 'COMPLETED'}
        onClick={handleClick}
        $duration={duration}
        $calView={calView}
        ref={buttonRef}
        type="button"
      >
        <Grid $xxsCol={calView === 'day' ? '1fr 40px' : '1fr'} $justifyItems="start">
          <Box>
            <Grid $xxsCol={calView === 'day' ? 'auto 1fr' : '1fr'} $gap="8px">
              {duration && duration > 60 && (
                <Text $xxsSize="12px" $lgSize={calView === 'day' ? '14px' : '12px'} $mono>
                  {lastStatus === 'FINISHED' || lastStatus === 'COMPLETED'
                    ? `${realStartingTime} - ${realFinishingTime}`
                    : `${bookedStartingTime} - ${bookedFinishingTime}`}
                </Text>
              )}
              {calView === 'day' && (
                <Text $xxsSize="12px" $lgSize="14px" $weight={600}>
                  {`${
                    lastStatus === 'FINISHED' || lastStatus === 'COMPLETED'
                      ? Math.ceil((workingTime || 0) / 60)
                      : duration
                  } ${t('MINUTE')}`}
                </Text>
              )}
            </Grid>
            {duration && duration > 45 && (
              <Text
                $margin={calView === 'week' ? '0px' : '0 0 4px 0'}
                $lgSize={calView === 'day' ? '16px' : '12px'}
                $lineHeight="1.2"
                $xxsSize="12px"
                $weight={600}
              >
                {`${surface && t(surface)} ${category?.title && t(category.title)}`}
              </Text>
            )}
            {!!vehicle?.licensePlate && (
              <Grid $xxsCol={calView === 'week' ? 'auto auto' : 'auto'} $justifyItems="start">
                <LicensePlate $event $weekEvent={calView === 'week'}>
                  {vehicle?.licensePlate}
                </LicensePlate>
                {vehicle?.size && calView === 'week' && <WashSize $weekEvent>{vehicle.size}</WashSize>}
              </Grid>
            )}
          </Box>
          {vehicle?.size && calView === 'day' && <WashSize>{vehicle.size}</WashSize>}
        </Grid>
      </EventItem>
      <Popover
        onClose={handleClose}
        anchorEl={anchorEl}
        elevation={0}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box>
          <CloseBtn onClick={() => handleClose()}>×</CloseBtn>
          <HeaderSection>
            <Text $lgSize="30px" $xxsSize="16px" $weight={600}>
              {`${!!surface && t(surface)} ${!!category && t(category.title)}`}
            </Text>
            {!!vehicle?.licensePlate && <LicensePlate $large>{vehicle?.licensePlate}</LicensePlate>}
          </HeaderSection>
          <PopoverContent>
            <Section>
              <Box>
                <Text $weight={500} $size="17px" $lettercase="capitalize">
                  {moment(start)
                    .locale(langMap[userLang] || langMap.en)
                    .format('MMMM DD.')}
                </Text>
                <Text $weight={500} $xxsSize="20px" $lgSize="30px">
                  {lastStatus === 'FINISHED' || lastStatus === 'COMPLETED'
                    ? `${realStartingTime} - ${realFinishingTime}`
                    : `${bookedStartingTime} - ${bookedFinishingTime}`}
                </Text>
              </Box>
              <Grid $xxsCol="auto auto" $alignItems="center" $justifyContent="start">
                <Text $weight={600} $size="16px">
                  {`${
                    lastStatus === 'FINISHED' || lastStatus === 'COMPLETED'
                      ? Math.ceil((workingTime || 0) / 60)
                      : duration
                  } ${t('MINUTE')}`}
                </Text>
                {lastStatus !== 'FINISHED' && (
                  <IconButton
                    onClick={() => {
                      dispatch(toggleEventCalEditing('EDIT', id, duration))
                      dispatch(toggleEventMoveModal(moment(start).format('YYYY-MM-DDTHH:mm:ssZ')))
                    }}
                    title={t('EDIT_DURATION_BTN')}
                    color="primary"
                    size="small"
                  >
                    <EditOutlined />
                  </IconButton>
                )}
              </Grid>
            </Section>
            <Section>
              <Box>
                <Text $weight={600}>{t('FULL_NAME')}</Text>
                <Text $weight={500}>{`${customerProfile?.firstName} ${customerProfile?.lastName}`}</Text>
              </Box>
              <Box>
                <Text $weight={600}>{t('SIZE')}</Text>
                <Text $weight={500}>{vehicle?.size}</Text>
              </Box>
              <Box>
                <Text $weight={600}>{t('EMAIL_ADDRESS')}</Text>
                <Text $weight={500}>
                  {customerProfile?.contacts?.length &&
                    customerProfile.contacts.find(({ channel }) => channel === 'EMAIL')?.value}
                </Text>
              </Box>
              <Box>
                <Text $weight={600}>{t('PHONE_NUMBER')}</Text>
                <Text $weight={500}>
                  {customerProfile?.contacts?.length &&
                    customerProfile.contacts.find(({ channel }) => channel === 'PHONE')?.value}
                </Text>
              </Box>
              <Box>
                <Text $weight={600}>{t('PRICE')}</Text>
                <Text $size="20px" $weight={500}>
                  {`${typeof event.discountedPrice === 'number' ? event.discountedPrice || 0 : event.price} ${t(
                    event.currency || 'HUF',
                  )} ${
                    (event.priceAdjustment &&
                      ` ${event.priceAdjustment > 0 ? '+' : ''} ${event.priceAdjustment} ${t(
                        event.currency || 'HUF',
                      )}`) ||
                    ''
                  }`}
                </Text>
                <Grid $mdCol={2}>
                  <Text $size="14px" $weight={500} $strikeThrough={!!event?.promotionalCode}>
                    {event?.promotionalCode ? `${event?.price} ${t(event.currency || 'HUF')}` : ''}
                  </Text>
                  <Text $size="14px" $weight={600} $textColor={colors.lime}>
                    {event.priceAdjustment &&
                      `= ${
                        (typeof event.discountedPrice === 'number' ? event.discountedPrice || 0 : event.price || 0) +
                        (event.priceAdjustment || 0)
                      } ${t(event.currency || 'HUF')}`}
                  </Text>
                </Grid>
              </Box>
              <Box>
                <Text $weight={600}>{t('PRICE_ON_SITE')}</Text>
                <Text $size="20px" $weight={500}>
                  {`${typeof event.payableOnSite === 'number' ? event.payableOnSite : event.price} ${t(
                    event.currency || 'HUF',
                  )} ${
                    (event.priceAdjustment &&
                      ` ${event.priceAdjustment > 0 ? '+' : ''} ${event.priceAdjustment} ${t(
                        event.currency || 'HUF',
                      )}`) ||
                    ''
                  }`}
                </Text>
                <Grid $mdCol={2}>
                  <Text $size="14px" $weight={500} $strikeThrough={!!event?.promotionalCode}>
                    {event?.promotionalCode ? `${event?.price} ${t(event.currency || 'HUF')}` : ''}
                  </Text>
                  <Text $size="14px" $weight={600} $textColor={colors.lime}>
                    {event.priceAdjustment &&
                      `= ${
                        (typeof event.discountedPrice === 'number' ? event.discountedPrice || 0 : event.price || 0) +
                        (event.priceAdjustment || 0)
                      } ${t(event.currency || 'HUF')}`}
                  </Text>
                </Grid>
              </Box>
            </Section>
            <ControlSection>
              <Grid justifyItems="end">
                <DetailsBtn component={Link} to={`/reservations/${id}`}>
                  {t('DETAILS')}
                </DetailsBtn>
              </Grid>
              {lastStatus !== 'FINISHED' && <DetailsActionButton {...buttonDetails} />}
            </ControlSection>
          </PopoverContent>
        </Box>
      </Popover>
    </div>
  )
}
export default Event
