/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreator, Dispatch } from 'redux'
import { price as serviceName } from '.'
import { API_URI } from '../config'
import { headers } from '../helpers/headers.helper'

export const getPrice: ActionCreator<any> =
  (
    washerId: number,
    packageId: number,
    customerProfileId?: number,
    promotionalCodeId?: number,
    licensePlate?: string,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: `GET_${serviceName}_BEGIN`,
      })
      const res = await fetch(
        `${API_URI}/washers/${washerId}/price?packageId=${packageId}${
          (!!customerProfileId && `&customerProfileId=${customerProfileId}`) || ''
        }${(!!licensePlate && `&licensePlate=${licensePlate}`) || ''}
        ${(!!promotionalCodeId && `&promotionalCodeId=${promotionalCodeId}`) || ''}`,
        {
          headers,
        },
      )
      const data = await res.json()
      dispatch({
        type: `GET_${serviceName}_SUCCESS`,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: `GET_${serviceName}_FAILED`,
        payload: error,
      })
    }
  }
