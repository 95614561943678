import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import {
  setLicensePlate,
  setNewOrderEmpty,
  setStartTime,
  setStep,
  setVehicleId,
} from '../../../actions/newReservationActions'
import { ModalWrapper, ModalHeader, ModalCloseBtn, ModalBody } from '../../../components/layout/modal'
import { newReservationSteps } from '../../../modules/modalBreadcrumbNavigaion/steps.data'
import { getReservations } from '../../../actions/getReservationsActions'
import VehicleSizeSelector from '../../../modules/vehicleSizeSelector'
import { IStore } from '../../../interfaces/store.interfaces'
import { getPrice } from '../../../actions/getPriceActions'
import { headers } from '../../../helpers/headers.helper'
import { Text, Grid } from '../../../components/layout'
import { API_URI, WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY } from '../../../config'
import {
  toggleNewReservationResultModal,
  toggleCancelNewReservationModal,
  toggleNewReservationModal,
  toggleNewProfileModal,
  setCompactDayOffset,
} from '../../../actions/uiStatesAction'
import {
  SelectAppointmentCalendar,
  ModalBreadcrumbNavigation,
  CustomerSelector,
  SelectedCustomer,
  PackageSelector,
  NewOrderSummary,
  SelectedPackage,
} from '../../../modules'
import { Button } from '../../../components/layout/button/button'
import { ItemWrapper } from '../../../components/layout/commonUiElements'
import { getTodaysReservations } from '../../../actions/getTodaysReservationsActions'

const NewReservationModal = (): ReactElement => {
  const { t } = useTranslation()
  const [nextDisabled, setNextDisabled] = useState(false)
  const [customDisabled, setCustomDisabled] = useState(false)
  const [withouthVehicle, setWithouthVehicle] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [cookies] = useCookies([WASHERID_COOKIE_KEY, PREFERRED_CALENDARVIEW_COOKIE_KEY])
  const washerId = Number(cookies[WASHERID_COOKIE_KEY])
  const calView = cookies[PREFERRED_CALENDARVIEW_COOKIE_KEY]
  const isOpen = useSelector((state: IStore) => state.uiStates.newReservationModalOpen)
  const packages = useSelector((state: IStore) => state.packages)

  const selectedPromotion = useSelector((state: IStore) => state.newOrderDetails.selectedPromotion)
  const customerProfileId = useSelector((state: IStore) => state.newOrderDetails.selectedProfileId)
  const bigCalendarDayOffset = useSelector((state: IStore) => state.uiStates.dayOffset)
  const vehicleSize = useSelector((state: IStore) => state.newOrderDetails.vehicleSize)
  const licensePlate = useSelector((state: IStore) => state.newOrderDetails.licensePlate)
  const categoryId = useSelector((state: IStore) => state.newOrderDetails.categoryId)
  const startTime = useSelector((state: IStore) => state.newOrderDetails.startTime)
  const packageId = useSelector((state: IStore) => state.newOrderDetails.packageId)
  const vehicleId = useSelector((state: IStore) => state.newOrderDetails.vehicleId)
  const step = useSelector((state: IStore) => state.newOrderDetails.step)
  const selectedPackage = packages.find(({ id }) => id === packageId)

  const startDay = moment(startTime).format('YYYY.MM.DD')
  const todayDay = moment().format('YYYY.MM.DD')
  const dayOffset = moment(new Date(startDay)).diff(new Date(todayDay), 'days')

  useEffect(() => {
    dispatch(setNewOrderEmpty())
  }, [])

  useEffect(() => {
    if (packageId) {
      dispatch(setStartTime(moment(selectedPackage?.nextAvailableTime).toDate()))
    }
  }, [packageId])

  useEffect(() => {
    if (step === 3 && washerId) {
      dispatch(setCompactDayOffset(dayOffset))
    }
  }, [step])

  useEffect(() => {
    if (step === 1) {
      setWithouthVehicle(false)
    }
  }, [step])

  const onSubmit = async () => {
    setLoading(true)
    const data = {
      promotionalCodeId: selectedPromotion?.id,
      customerProfileId,
      categoryId,
      packageId,
      startTime: moment(startTime).format('YYYY-MM-DDTHH:mm:ssZ'),
      vehicleId,
    }

    const postUrl = `${API_URI}/washers/${washerId}/reservations`
    const response = await fetch(postUrl, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
    const result = await response.json()
    if (result.error) {
      setLoading(false)
      dispatch(toggleNewReservationResultModal('ERROR'))
    }
    if (result.id && !!washerId) {
      setLoading(false)
      dispatch(toggleNewReservationResultModal('SUCCESS', result.id))
      if (calView === 'day') {
        dispatch(getReservations(washerId, bigCalendarDayOffset))
      }
      if (calView === 'week') {
        const weekStartOffsetFromToday = moment().add(bigCalendarDayOffset, 'day').weekday()
        const weekStartOffsetFromDate = weekStartOffsetFromToday * -1 + bigCalendarDayOffset
        dispatch(getReservations(washerId, weekStartOffsetFromDate, 7))
      }
      dispatch(toggleNewReservationModal())
      dispatch(getTodaysReservations(washerId))
      dispatch(setNewOrderEmpty())
      setWithouthVehicle(false)
      dispatch(setStep(1))
    }
  }

  const onHandleSteps = (stepNum: number) => {
    dispatch(setStep(stepNum))
  }

  // RULES TO DISABLE NEXT STEP
  useEffect(() => {
    if (
      (step === 1 && customerProfileId === undefined) ||
      (step === 1 && !withouthVehicle && !vehicleId) ||
      (step === 1 && withouthVehicle && !vehicleSize)
    ) {
      setNextDisabled(true)
    } else if (step === 2 && packageId === undefined) {
      setNextDisabled(true)
    } else {
      setNextDisabled(false)
    }
  }, [step, packageId, customerProfileId, vehicleId, vehicleSize])

  // RULES TO DISABLE CUSTOM BUTTON
  useEffect(() => {
    if (step === 1 && customerProfileId === undefined) {
      setCustomDisabled(true)
    } else {
      setCustomDisabled(false)
    }
  }, [step, customerProfileId])

  const withoutVehicle = () => {
    setWithouthVehicle(true)
    dispatch(setVehicleId(undefined))
    dispatch(setLicensePlate(undefined))
  }

  useEffect(() => {
    if (washerId && packageId) {
      dispatch(getPrice(washerId, packageId, customerProfileId, selectedPromotion?.id, licensePlate))
    }
  }, [washerId, packageId, customerProfileId, selectedPromotion])

  return (
    <Modal open={isOpen}>
      <Fade in={isOpen}>
        <ModalWrapper $fullWidth>
          <ModalHeader>
            <Grid $xxsCol="1fr auto" $marginRight="35px" $alignItems="center">
              <Text $lgSize="30px" $xxsSize="20px" $weight={500}>
                {step === 4 ? t('SUMMARY') : t('NEW_APPOINTMENT')}
              </Text>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  dispatch(toggleNewProfileModal())
                }}
              >
                {t('NEW_PROFILE_BTN')}
              </Button>
            </Grid>
            <ModalCloseBtn onClick={() => dispatch(toggleCancelNewReservationModal())}>×</ModalCloseBtn>
          </ModalHeader>
          <ModalBody>
            {step === 1 && !withouthVehicle && <CustomerSelector />}
            {step === 1 && withouthVehicle && <VehicleSizeSelector />}
            {step === 2 && (
              <Grid>
                <SelectedCustomer />
                <PackageSelector />
              </Grid>
            )}
            {step === 3 && (
              <Grid $gap="0px">
                <SelectedCustomer />
                <ItemWrapper>
                  <SelectedPackage />
                </ItemWrapper>
                <SelectAppointmentCalendar />
              </Grid>
            )}
            {step === 4 && <NewOrderSummary />}
          </ModalBody>
          <ModalBreadcrumbNavigation
            customButton={{ step: 1, text: 'CONTINUE_WITH_DIFFERENT_VEHICLE', visible: step === 1 && !withouthVehicle }}
            onCustomFunction={withoutVehicle}
            customDisabled={customDisabled}
            onHandleSteps={onHandleSteps}
            steps={newReservationSteps}
            nextDisabled={nextDisabled}
            onHandleFinish={onSubmit}
            loading={loading}
            step={step || 1}
          />
        </ModalWrapper>
      </Fade>
    </Modal>
  )
}

export default NewReservationModal
